<!-- 棋牌游戏 -->
<template>
  <div>
    <div class="content main-lobby">
      <div class="section section-main">
        <div class="sponsor-category">
          <div class="bg-color-wrap"></div>
          <div class="sponsor-wrap cate-1">
            <img
              src="https://static-web.8ar7dbcwtr.com/knor6u/static/images/sponsorship/sponsorship_cpl.png"
            />
            <a
              href="/in/sponsorships#cpl"
              onclick="$('.cpl-icon').click();"
            ></a>
          </div>

          <div class="sponsor-wrap cate-2">
            <img
              src="https://static-web.8ar7dbcwtr.com/knor6u/static/images/sponsorship/sponsorship_lagishart.png"
            />
            <a
              href="/in/sponsorships#lagishart"
              onclick="$('.shart-icon').click();"
            ></a>
          </div>

          <div class="sponsor-wrap cate-3">
            <img
              src="https://static-web.8ar7dbcwtr.com/knor6u/static/images/sponsorship/sponsorship_other.png"
            />
            <a
              href="/in/sponsorships#other"
              onclick="$('.other-icon').click();"
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sponsorship-text page-text">
      <div class="layout" :class="{ 'layout-height': moreShow }">
        <h4>
          Leading Online Gaming Site, Fun88, Sponsors &amp; Partners With Major
          Sporting Leagues Globally
        </h4>
        <p>
          Fun88, Asia’s most trusted gaming site, partners with the most
          exciting T20 cricket tournament, the Caribbean Premier League. Fun88
          is the official betting partner of the biggest party in sports, CPL
          T20, from 2020 to 2023.
        </p>
        <p>
          We take great pride in the fact that a professional sports league like
          CPL with more than 400mn followers across the globe has associated
          with Fun88 for 3 years.
        </p>
        <p>
          We provide some of the most competitive betting odds, tips, insights,
          superb prizes and rewards and most importantly, a platform that lets
          you play and transfer funds in the most secure manner.
        </p>
        <p>
          An online gaming company, Fun88, is also the official shirt sponsor of
          the English Premier League (EPL) team,
          <a href="https://www.nufc.co.uk/" target="_blank">Newcastle United</a
          >. Even though the earlier partnership expired in 2019-20, Fun88 and
          Newcastle United have gone ahead to sign a long-term extension that
          shows the faith the club has in us.
        </p>
        <p>
          As a part of this partnership, the Newcastle United team will have
          Fun88’s logo on their playing and training kits. The fact that a top
          club has shown faith in our ability is really motivating for us. We’re
          inspired to take sports entertainment and betting to more and more
          people in Asia.
        </p>
        <p>
          We’re also immensely proud of partnering with Tottenham Hotspur,
          another EPL team, as its official betting partner in Asia. This
          partnership will help us engage with sports fans and bettors at a much
          larger scale than earlier. To the millions of Tottenham Hotspur fans
          worldwide, Fun88 will provide a trusted betting platform and loads of
          sports entertainment.
        </p>
        <p>
          The terms of agreement allow Fun88 to have in-stadium advertising
          presence at the
          <a href="https://www.tottenhamhotspur.com/" target="_blank"
            >Tottenham Hotspur</a
          >
          club’s home matches on the LED perimeter boards.
        </p>
        <p>
          By striking the best sports partnership and sponsorship deals,
          <router-link to="/home" target="_blank">Fun88</router-link> is
          providing excellent betting opportunities to punters in India, Asia,
          and other parts of the world.<router-link
            to="/author?type=1"
            target="_blank"
          >
            Join NOW</router-link
          >!
        </p>
      </div>
      <div @click="moreChange" class="read-more">READ MORE</div>
    </div>
  </div>
</template>

<script>
import { _getToken } from "../../core/comp/common";
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import {
  _thirdList,
  _searchGameAction,
  _recommendGameByIDAction,
} from "../../core/comp/pages/games";

export default {
  data() {
    return {
      curId: "",
      loading: false,
      swiperOption: {
        //swiper3
        // autoplay: 3000,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 30,
        // loop:true,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        pagination: ".swiper-pagination",
        paginationType: "fraction",
      },
      searchText: "", // 当前搜索的内容
      searchList: [], // 搜索结果

      activeGame: "", // 当前激活的游戏平台ID
      activeTab: 1, // 当前激活的分类 1-全部  2-热门游戏
      newList: [], // 分割之后的新数组
      pages: {
        // 分页管理
        currPage: 1,
        pageSize: 24,
      },
      moreShow: true,
    };
  },
  watch: {
    activeGame(val) {
      // 监听平台变化
      if (this.gameChessPages[val]) {
        // 当平台变化时发现该平台有缓存的数据 则直接展示
        this.pages = JSON.parse(JSON.stringify(this.gameChessPages[val]));
      }
    },
    // lists (val) { // 监听二级分类数据变化，当变化时默认获取第一个元素的子游戏列表
    //     if (val && val[0]) {
    //         this.tapGame(val[0]) // 当前页刷新时的初始化
    //     }
    // }
    // "$route": {
    //   handler: function (val) {
    //     if (val) {
    //       let id = val?.query?.id;
    //       if (id != 5) {
    //         let obj = this.lists.find((item) => {
    //           return item.id == id;
    //         });
    //         this.tapGame(obj); // 初始化
    //       } else {
    //         this.tapGame(this.lists[0]); // 初始化
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    this.splitList();
  },
  computed: {
    showList() {
      // 展示的游戏列表
      if (this.activeGame) {
        // 全部游戏列表
        if (this.activeTab == 1)
          return this.gameChessLists[this.activeGame] || [];
        // 热门游戏列表
        if (this.activeTab == 2)
          return this.gameChessRecommen[this.activeGame] || [];
      }
      return this.searchList; // 搜索结果
    },
    listTotal() {
      // 列表总数
      if (this.gameChessPages[this.activeGame])
        return this.gameChessPages[this.activeGame].total;
      return 0;
    },
    lists() {
      // 二级导航
      return this.gameMenu.arr_qp || [];
    },
    ...mapGetters([
      "isLogin",
      "gameMenu", // 游戏分类
      "gameChessLists", // 三级游戏 全部列
      "gameChessPages", // 三级游戏 全部列表的页面状态
      "gameChessRecommen", // 三级游戏 热门列
    ]),
  },
  methods: {
    moreChange() {
      this.moreShow = !this.moreShow;
    },
    _getToken,
    // 数组切割 列表 每组几个
    splitList() {
      for (let i = 0; i < this.lists.length; i += 8) {
        this.newList.push(this.lists.slice(i, i + 8));
      }
    },
    // 搜索
    search() {
      if (!this.isLogin) return Message.warning(this.$t("errorMessage.text1"));
      if (this.searchText === "") return;
      this.curId = "";
      this.activeGame = "";
      this.activeTab = 1;
      console.log("---搜索", this.searchText);
      setTimeout(() => {
        this.searchList = [];
        _searchGameAction(this.searchText).then((res) => {
          if (res) this.searchList = res;
        });
      }, 0);
    },
    // 翻页
    pageChange() {
      this.activeTab = 1; // 锁定为全部游戏
      this.tapGame(this.lists.find((item) => item.id == this.activeGame));
    },
    // 点击游戏平台
    tapGame(item) {
      if (this.loading) return;
      this.curId = item.id;
      this.activeTab = 1;
      console.log("---点击了", item);
      this.activeGame = item.id;
      setTimeout(() => {
        // 如果发现当前缓存的是正要请求的该页数据，则不用发送请求，直接用缓存即可，可以减少大量请求
        if (
          this.gameChessPages[this.activeGame] &&
          this.gameChessPages[this.activeGame].currPage == this.pages.currPage
        )
          return;
        this.loading = true;
        _thirdList({ item, pages: this.pages }, "chess").then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
      }, 0);
    },
    // 获取推荐游戏
    getRecommend() {
      this.activeTab = 2; // 锁定为推荐游戏
      const item = this.lists.find((item) => item.id == this.activeGame);
      setTimeout(() => {
        console.log("---推荐", item);
        _recommendGameByIDAction(item, 3);
      }, 0);
    },
  },
};
</script>
<style lang="less" scoped>
.content.main-lobby {
  background: #ffffff;
  max-height: calc(37vw + 50px);
  padding: 0;
  margin: 0;
  .section-main {
    height: unset;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
    .sponsor-category {
      display: inline-flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      position: relative;
      padding: 50px 0 0;
      .bg-color-wrap {
        background: #e7f3fb;
        height: 50%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
      }
      .sponsor-wrap {
        width: 25%;
        margin: 0 1%;
        position: relative;
        img {
          width: 100%;
          vertical-align: top;
          border-style: none;
          border: 0;
        }
        a {
          background: url(../../assets/images/in/know_more_btn.png) no-repeat
            center center/contain;
          position: relative;
          bottom: 15%;
          margin: auto;
          width: 50%;
          height: 67px;
          display: block;
          text-align: center;
          color: #fff;
          text-decoration: none;
          outline: none;
        }
      }
    }
  }
}
.sponsorship-text {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}
.page-text {
  max-width: 1400px;
  margin: 0 auto;
  .layout-height {
    height: 300px;
  }
  .layout {
    max-width: 1200px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #00a6ff;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      word-break: break-word;
      color: #404040;
      line-height: 24px;
      margin: 0 0;
      padding: 0 0 15px 0;
    }
    a {
      color: #00a6ff;
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .read-more {
    width: 241px;
    height: 41px;
    margin: 30px auto;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #00a6ff;
    line-height: 41px;
    text-align: center;
    color: #00a6ff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>